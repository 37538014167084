// TicketItem.tsx
import React from 'react';
import styles from './styles.module.css';
import { HSTicket } from '@robotical/analytics-gatherer/dist';
import { HubspotTicketStatus } from '@robotical/analytics-gatherer/dist/types';

interface TicketItemProps {
  ticket: HSTicket;
  onUpdateStatus: (status: HubspotTicketStatus) => void;
}

const TicketItem: React.FC<TicketItemProps> = ({ ticket, onUpdateStatus }) => {
  // the description should stop when the HwStatus: is found
  const editedDescription = "An issue was automatically detected by the system."
  return (
    <div className={styles.ticketItem}>
      <h2>Automaticaly Generated Ticket</h2>
      <p className={styles.ticketDescription}>{editedDescription}</p>
      <p>Ticket Status: {ticket.status}</p>
      {ticket.status !== 'Closed'
        ? <button className={[styles.button, styles.button_close].join(" ")} onClick={() => onUpdateStatus(HubspotTicketStatus.CLOSED)}>Close Ticket</button>
        : <button className={[styles.button, styles.button_open].join(" ")} onClick={() => onUpdateStatus(HubspotTicketStatus.OPEN)}>Reopen Ticket</button>}
    </div>
  );
};

export default TicketItem;