import React from 'react';
import UserRobots from '../../components/UserRobots';
import UserDetails from '../../components/UserDetails';
import { RegisteredUser } from '@robotical/analytics-gatherer/dist';
import UserConversations from '../../components/UserConversations';

type Props = {
    user: RegisteredUser;
    setUser: (user: RegisteredUser) => void;
    refreshProfile: () => void;
};

const UserProfile: React.FC<Props> = ({ user, setUser, refreshProfile }) => {

    if (!user) {
        return <div>Please log in</div>;
    }

    return (
        <>
            <UserDetails user={user} setUser={setUser} refreshProfile={refreshProfile} />
            <UserConversations user={user} />
            <UserRobots user={user} setUser={setUser} />
        </>
    );
};

export default UserProfile;
