import React from 'react';
import styles from './styles.module.css';

const LoadingSpinner: React.FC = () => {
    return (
        <div className={styles.loader}></div>
    );
};

export default LoadingSpinner;
