export type HubspotConversationResponse = {
    contact: string;
    conversations: {
        openConversations: HubspotConversation[];
        closedConversations: HubspotConversation[];
    }
}

type HubspotConversation = HubspotConversationEvent[];

type HubspotConversationEvent = {
    id: string;
    archived: boolean;
    client: { clientType: string };
    conversationsThreadId: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    status: { statusType: HubspotConversationEventStatus };
    richText: string;
    subject: string;
    text: string;
    truncationStatus: HubspotConversationEventTruncationStatus;
    recipients: any[];
    senders: any[];
    type: HubspotConversationEventType;
}

enum HubspotConversationEventType {
    THREAD_STATUS_CHANGE = "THREAD_STATUS_CHANGE",
    MESSAGE = "MESSAGE",
}

enum HubspotConversationEventStatus {
    SENT = "SENT",
    RECEIVED = "RECEIVED",
}

enum ConversationStatus {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
}

enum HubspotConversationEventTruncationStatus {
    TRUNCATED_TO_MOST_RECENT_REPLY = "TRUNCATED_TO_MOST_RECENT_REPLY",
}

/* Manages conversation transformations */
export default class ConversationTransformer {

    /* Transform a Hubspot conversations object into appropriate Conversation[] */
    static transformConversations(conversations: HubspotConversationResponse[]): Conversation[] {
        const conversationObjects: Conversation[] = [];
        conversations.forEach((conversations_) => {
            conversations_.conversations.openConversations.forEach((conversation, conversationIndex) => {
                const messages: Message[] = [];
                conversation.forEach((conversationEvent) => {
                    if (conversationEvent.type === HubspotConversationEventType.MESSAGE) {
                        messages.push(this.transformMessage(conversationEvent, conversations_.contact, ConversationStatus.OPEN));
                    }
                });
                conversationObjects.push(this.transformConversation(conversation[conversationIndex], conversations_.contact, ConversationStatus.OPEN, messages));
            });

            conversations_.conversations.closedConversations.forEach((conversation, conversationIndex) => {
                const messages: Message[] = [];
                conversation.forEach((conversationEvent) => {
                    if (conversationEvent.type === HubspotConversationEventType.MESSAGE) {
                        messages.push(this.transformMessage(conversationEvent, conversations_.contact, ConversationStatus.CLOSED));
                    }
                });
                conversationObjects.push(this.transformConversation(conversation[conversationIndex], conversations_.contact, ConversationStatus.CLOSED, messages));
            });
        });
        return conversationObjects;
    }

    /* Transform a Hubspot conversation into a Conversation */
    static transformConversation(conversation: HubspotConversationEvent, contact: string, conversationStatus: ConversationStatus, messages: Message[]): Conversation {
        return new Conversation(
            conversation.id,
            contact,
            conversationStatus,
            messages
        );
    }

    /* Transform a Hubspot conversation event into a Message */
    static transformMessage(conversationEvent: HubspotConversationEvent, contact: string, conversationStatus: ConversationStatus): Message {
        return new Message(
            conversationEvent.subject,
            conversationEvent.status.statusType,
            conversationEvent.text,
            new Date(conversationEvent.createdAt),
            new Date(conversationEvent.updatedAt),
        );
    }

}

/* A Hubspot Conversation */
export class Conversation {

    constructor(
        public id: string,
        public contact: string,
        public conversationStatus: ConversationStatus,
        public messages: Message[]
    ) {
        this.orderMessagesByDate();
    }

    orderMessagesByDate() {
        this.messages.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
    }

}

class Message {

    constructor(
        public subject: string,
        public messageStatus: HubspotConversationEventStatus,
        public text: string,
        public createdAt: Date,
        public updatedAt: Date,
    ) {

    }

}

