import { RICConnector } from "@robotical/ricjs";
import { ServiceProgramDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import serviceProgramFirebaseConfig from "../database/config";

export async function getSerialNumberFromDevice(device: BluetoothDevice) {
    try {
        const ricConnector = new RICConnector();
        const isConnected = await ricConnector.connect("WebBLE", device);
        if (isConnected) {
            const ricSystem = ricConnector.getRICSystem();
            const systemInfo = await ricSystem.getRICSystemInfo(true);
            const serialNumber = systemInfo.SerialNo;
            await ricConnector.disconnect();
            return serialNumber;
        }
        disconnectRepeatedly(ricConnector, 500, 2000);
        return undefined;
    } catch (error) {
        console.error(`Failed to get serial number from device: ${error}`);
        return undefined;
    }
}

async function disconnectRepeatedly(ricConnector: RICConnector, interval: number, timeout: number) {
    const startTime = Date.now();
    const intervalId = setInterval(async () => {
        const isConnected = ricConnector.isConnected();
        if (!isConnected || Date.now() - startTime > timeout) {
            clearInterval(intervalId);
        } else {
            try {
                await ricConnector.disconnect();
            } catch (error) {
                console.error(`Failed to disconnect from device: ${error}`);
            }
        }
    }, interval);
}


export const isSerialNumberRegistered = async (serialNo: string): Promise<boolean> => {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        return dbInstance.isSerialNumberRegistered(serialNo);
    } catch (error) {
        console.error(`Failed to check if serial number is registered: ${error}`);
        return false;
    }
};

export const addRobotsToUser = async (email: string, serialNumbers: string[]) => {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        return dbInstance.appendSerialNumbersToUser(email, serialNumbers);
    } catch (error) {
        console.error(`Failed to add robots to user: ${error}`);
        return false;
    }
}