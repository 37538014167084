import styles from "./styles.module.css";

type PlotsArrangementProps = {
    children: React.ReactNode | React.ReactNode[];
}

export default function PlotsArrangement({children}: PlotsArrangementProps) {
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
}
