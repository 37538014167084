import { Layout, Legend, RangeSelector, RangeSlider } from "plotly.js";
import { MAIN_BLUE, GREY, SCREENFREE_BLUE, SAND_YELLOW, SCREENFREE_YELLOW, RED, GREY_2, GREY_3, GREY_4 } from "../../../styles/colors";

const COLORS = [
  MAIN_BLUE,
  GREY,
  SCREENFREE_BLUE,
  SAND_YELLOW,
  SCREENFREE_YELLOW,
  RED
];

const randomColor = () => {
  const idx = Math.floor(Math.random() * COLORS.length);
  return COLORS[idx];
};

export const axisFontStyle = {
  family: "Lato Regular",
  color: "white",
  size: 12,
};

export const plotMargins = {
  l: 60,
  r: 20,
  b: 20,
  t: 20,
  pad: 2,
};

export const legendInPlot: Partial<Legend> = {
  xanchor: "right",
  x: 1,
  y: 1,
  bgcolor: GREY_2,
  font: {
    color: "black",
  },
};

export const legendOutsidePlot: Partial<Legend> = {
  // xanchor: "right",
  x: 1,
  y: 0.5,
  // bgcolor: GlobalColors.LIGHT_GREY_2,
  // font: {
    // color: "black",
  // },
};

export const barPlotLayout = (): Partial<Layout> => {
  return {
    autosize: true,
    margin: plotMargins,
    bargap: 0.5,
    legend: legendOutsidePlot,
    yaxis: {
      title: "Count",
      titlefont: {
        ...axisFontStyle,
        size: 16,
      },
      gridcolor: "rgba(22, 67, 78, .8)",
      tickfont: axisFontStyle,
    },
    xaxis: {
      tickfont: axisFontStyle,
    },
    plot_bgcolor: GREY_2,
    paper_bgcolor: GREY_2,
  };
};

export const rangeSelector: Partial<RangeSelector> = {
  buttons: [
    {
      count: 1,
      label: "1m",
      step: "month",
      stepmode: "backward",
    },
    {
      count: 6,
      label: "6m",
      step: "month",
      stepmode: "backward",
    },
    { step: "all" },
  ],
};

export const lineGraphLayout: Partial<Layout> = {
  autosize: true,
  margin: plotMargins,
  bargap: 0.5,
  legend: legendInPlot,
  yaxis: {
    autorange: true,
    titlefont: {
      ...axisFontStyle,
      size: 16,
    },
    // gridcolor: "rgba(22, 67, 78, .8)",
    tickfont: axisFontStyle,
  },
  xaxis: {
    tickfont: axisFontStyle,
    type: "date",
    rangeselector: rangeSelector,
  },
  plot_bgcolor: GREY_3,
  paper_bgcolor: GREY,
};

export const rangeSlider = (low: Date, high: Date): Partial<RangeSlider> => {
  return {
    range: [low, high],
  };
};
