import { RegisteredUser } from "@robotical/analytics-gatherer/dist";
import { createContext, useState } from "react";

type UserContextType = {
    user: RegisteredUser | null;
    setUser: (user: RegisteredUser) => void;
};

const UserContext = createContext<UserContextType>({
    user: null,
    setUser: (user: RegisteredUser | null) => { }
});

export const UserProvider = ({ children }: any) => {
    const [user, setUser] = useState<RegisteredUser | null>(null);


    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;