import { DatabaseEnum, DatabaseManager, ServiceProgramDatabase } from "@robotical/analytics-gatherer";
import serviceProgramFirebaseConfig from "./config";

export default class ConsentManager {
    static async getDb() {
        try {
            const dbManager = DatabaseManager.getInstance();
            const dbInstance = await dbManager.initializeOrGetDatabase(
                DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
            ) as ServiceProgramDatabase;
            return dbInstance;
        } catch (e) {
            console.error("error getting db:", e);
            return null;
        }
    }

    static async setAutomatedFaultReportingConsent(email: string, consent: boolean) {
        const db = await this.getDb();
        if (!db) {
            return;
        }
        return db.setAutomatedFaultReportingConsent(email, consent);
    }

    static async setAnalyticsConsent(email: string, consent: boolean) {
        const db = await this.getDb();
        if (!db) {
            return;
        }
        return db.setAnalyticsConsent(email, consent);
    }
    
}