import { DatabaseEnum, DatabaseManager, ServiceProgramDatabase } from "@robotical/analytics-gatherer/dist";
import serviceProgramFirebaseConfig from "./config";

export default class ConversationsFetcher {

    static async getDb() {
        try {
            const dbManager = DatabaseManager.getInstance();
            const dbInstance = await dbManager.initializeOrGetDatabase(
                DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
            ) as ServiceProgramDatabase;
            return dbInstance;
        } catch (e) {
            console.error("error getting db:", e);
            return null;
        }
    }

    static async fetchConversationsGivenEmail(email: string) {
        const db = await this.getDb();
        if (db) {
            const conversations = await db.getHubspotConversationsForEmail(email);
            if (!conversations) {
                return { openConversations: [], closedConversations: [] };
            }
            return conversations;
        } else {
            return { openConversations: [], closedConversations: [] };
        }
    }

}
