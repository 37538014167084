import React, { useState } from 'react';
import styles from './styles.module.css';
import { doesUserExist, storeLoginToken } from '../../database/login';
import { userDoesNotExistWarning } from '../../toasts/login';
import { toast } from 'react-toastify';
import RoboticalLogo from "../../assets/images/bootsplash_logo@2x.png";

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        // we first check if the email exists in the database
        const userExists = await doesUserExist(email);
        if (!userExists) {
            userDoesNotExistWarning();
            setIsLoading(false);
            return;
        }
        // if it does, we store a unique token in the database under the user's email
        const loginToken = await storeLoginToken(email);
        if (!loginToken) {
            toast.error('Error generating login link, please try again later.');
            console.log('Error storing login token');
            setIsLoading(false);
            return;
        }

        fetch('https://d88ja061rl.execute-api.us-east-1.amazonaws.com/prod/sendMail', {
            method: 'POST',
            body: JSON.stringify({
                email,
                subject: 'Robotical - Login Link',
                queryString: loginToken.queryString,
            }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(async (response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success('Email sent successfully! Please check your inbox.');
                } else {
                    toast.error('Error sending email, please try again later.');
                }
                setIsLoading(false);
            })
            .catch(error => {
                toast.error(`Error sending email: ${error}`);
                console.error('Error:', error);
                setIsLoading(false);
            });
    };

    return (
        <div className={styles.container}>
            <img src={RoboticalLogo} alt="Robotical Logo" className={styles.logo} />
            <h1>Welcome to the User Dashboard</h1>
            {window.location.search && <p className={styles.message}>This login link is not valid anymore, please request a new link.</p>}
            <form onSubmit={handleLogin} className={styles.form}>
                <label htmlFor="email" className={styles.label}>
                    Enter your email to get a new login link
                </label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className={styles.input}
                    required
                />
                <button type="submit" className={isLoading ? styles.buttonDisabled : styles.button} disabled={isLoading}>Request Login Link</button>
                {isLoading && <p className={styles.message}>Please wait...</p>}
            </form>
        </div>
    );
};

export default LoginPage;
