import React, { useState } from 'react';
import { RegisteredUser } from '@robotical/analytics-gatherer/dist';
import styles from './styles.module.css';
import Modal from "../Modal";
import ManageEmails from '../../modals-content/ManageEmails';
import Checkbox from '../Checkbox';
import ConsentManager from '../../database/ConsentManager';

type Props = {
    user: RegisteredUser;
    setUser: (user: RegisteredUser) => void;
    refreshProfile: () => void;
};

const UserDetails: React.FC<Props> = ({ user, setUser, refreshProfile }) => {
    const [editing, setEditing] = useState(false);
    const [editedUser, setEditedUser] = useState<RegisteredUser | null>(user);
    const [isModalClosing, setIsModalClosing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [isAutomatedFaultReportingEnabled, setIsAutomatedFaultReportingEnabled] = useState(false);
    // const [isAnalyticsConsentGiven, setIsAnalyticsConsentGiven] = useState(false);

    if (!user) {
        return <div>Please log in</div>;
    }

    const closeModalHandler = () => {
        const timer = setTimeout(() => {
            setIsModalClosing(false);
            setIsModalOpen(false);
            document
                .getElementsByTagName("body")[0]
                .setAttribute("style", "overflow: auto");
            clearTimeout(timer);
        }, 7);
        setIsModalClosing(true);
    };

    const handleEdit = () => {
        setEditing(true);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!editedUser) return;
        setEditedUser({ ...editedUser, [event.target.name]: event.target.value });
    };

    const handleSave = () => {
        if (editedUser) {
            setUser(editedUser);
        }
        setEditing(false);
    };

    const handleManageEmails = () => {
        setIsModalOpen(true);
    }

    const handleAnalyticsConsentClick = async (value: boolean) => {
        await ConsentManager.setAnalyticsConsent(user.email, value);
        refreshProfile();
    }

    const handleAutomatedFaultReportingConsentClick = async (value: boolean) => {
        await ConsentManager.setAutomatedFaultReportingConsent(user.email, value);
        refreshProfile();
    }

    const registrationDate = new Date(user.date);
    return (
        <>
            <Modal
                show={isModalOpen}
                closing={isModalClosing}
                title={"Manage emails"}
                onClose={closeModalHandler}
            >
                <ManageEmails primaryEmail={user.email} alternativeEmails={user.alternativeEmails || []} refreshProfile={refreshProfile} />
            </Modal>
            <div className={styles.profileContainer}>
                <h1>User Details</h1>
                <div className={styles.profileItem}>
                    <label>Email: </label>
                    {editing ? (
                        <input type="text" name="email" value={editedUser?.email} onChange={handleChange} />
                    ) : (
                        <span>{user.email}</span>
                    )}
                </div>
                <div className={styles.profileItem}>
                    <label>Alternative Emails: </label>
                    <span>{(user.alternativeEmails || []).join(', ')}</span>
                </div>
                <div className={styles.profileItem}>
                    <button className={styles.button} onClick={handleManageEmails}>Manage Emails</button>
                </div>
                <div className={styles.profileItem}>
                    <label>Establishment/School: </label>
                    {editing ? (
                        <input type="text" name="establishment" value={editedUser?.establishment} onChange={handleChange} />
                    ) : (
                        <span>{user.establishment}</span>
                    )}
                </div>
                <div className={styles.profileItem}>
                    <label>Registration Date: </label>
                    <span>{`${registrationDate.getDate()} ${registrationDate.toLocaleString('default', { month: 'long' })} ${registrationDate.getFullYear()}`}</span>
                </div>
                <div className={styles.profileItem}>
                    <label>Should automated fault reporting be enabled?</label>
                    <Checkbox
                        checked={user.automatedFaultReportingConsent === undefined ? true : user.automatedFaultReportingConsent}
                        onChange={handleAutomatedFaultReportingConsentClick}
                    />
                </div>
                <div className={styles.profileItem}>
                    <label>Should we collect analytics data?</label>
                    <Checkbox
                        checked={user.analyticsConsent === undefined ? true : user.analyticsConsent}
                        onChange={handleAnalyticsConsentClick}
                    />
                </div>

                {/* {editing ? (
                    <button className={styles.button} onClick={handleSave}>Save</button>
                ) : (
                    <button className={styles.button} onClick={handleEdit}>Edit</button>
                )} */}
            </div>
        </>
    );
};

export default UserDetails;
