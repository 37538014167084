import styles from "./styles.module.css";

type InfoHoverProps = {
    children: React.ReactNode;
    tooltipId: string;
    infoText: string;
};

function InfoHover({children, tooltipId, infoText}:InfoHoverProps) {
    return (
        <span data-tip={infoText} data-for={tooltipId} className={styles.span_styled}>{children}</span>
    );
}


export default InfoHover;