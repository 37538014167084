import React, { useEffect, useState } from 'react';
import { RegisteredUser } from '@robotical/analytics-gatherer/dist';
import styles from './styles.module.css';
import Modal from "../Modal";
import LoadingSpinner from '../LoadingSpinner';
import ConversationsFetcher from '../../database/ConversationsFetcher';
import ConversationTransformer, { Conversation, HubspotConversationResponse } from '../../models/Conversation';
import EmailConversation from './EmailConversation';

type Props = {
    user: RegisteredUser;
};

const UserConversations: React.FC<Props> = ({ user }) => {
    const [isModalClosing, setIsModalClosing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [isConversationsComponentExpanded, setIsConversationsComponentExpanded] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const conversationPromises = [
            ConversationsFetcher.fetchConversationsGivenEmail(user.email),
        ];
        if (user.alternativeEmails) {
            user.alternativeEmails.forEach((alternativeEmail) => {
                conversationPromises.push(ConversationsFetcher.fetchConversationsGivenEmail(alternativeEmail));
            });
        }
        Promise.all(conversationPromises).then((conversations) => {
            const primaryEmailConversations = conversations[0];
            const alternativeEmailConversations = conversations.slice(1);
            const conversationObjects: HubspotConversationResponse[] = [{ contact: user.email, conversations: primaryEmailConversations as any }];
            for (let i = 0; i < alternativeEmailConversations.length; i++) {
                if (!user.alternativeEmails || i > user.alternativeEmails.length) {
                    break;
                }
                conversationObjects.push({ contact: user.alternativeEmails[i], conversations: alternativeEmailConversations[i] as any });
            }
            const transformedConversations = ConversationTransformer.transformConversations(conversationObjects);
            setConversations(transformedConversations);
            setIsLoading(false);
        });

    }, []);

    if (!user) {
        return <div>Please log in</div>;
    }

    const closeModalHandler = () => {
        const timer = setTimeout(() => {
            setIsModalClosing(false);
            setIsModalOpen(false);
            document
                .getElementsByTagName("body")[0]
                .setAttribute("style", "overflow: auto");
            clearTimeout(timer);
        }, 7);
        setIsModalClosing(true);
    };


    return (
        <>
            <Modal
                show={isModalOpen}
                closing={isModalClosing}
                title={"Manage emails"}
                onClose={closeModalHandler}
            >
                <div>modal</div>
            </Modal>
            <div className={styles.profileContainer}>
                <h1 onClick={() => setIsConversationsComponentExpanded(!isConversationsComponentExpanded)} className={styles.conversationsHeader}>
                    Account's Conversations with us
                    <span className={styles.expandButtonIcon}>
                        {isConversationsComponentExpanded ? '-' : '+'}
                    </span>
                </h1>
                {isConversationsComponentExpanded && (isLoading ? <LoadingSpinner /> :
                    conversations.length === 0 ? <div>No conversations found</div> :
                        conversations.map((conversation, index) => {
                            return <EmailConversation key={index} conversation={conversation} />;
                        })
                )}
            </div>
        </>
    );
};

export default UserConversations;
