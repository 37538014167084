import { DatabaseEnum, DatabaseManager, LoginToken, ServiceProgramDatabase } from "@robotical/analytics-gatherer";
import serviceProgramFirebaseConfig from "./config";

export const doesUserExist = async (email: string) => {
    const dbManager = DatabaseManager.getInstance();
    const dbInstance = await dbManager.initializeOrGetDatabase(
        DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
    ) as ServiceProgramDatabase;
    const user = await dbInstance.getUser(email);
    return !!user;
};

export const storeLoginToken = async (email: string): Promise<LoginToken | false> => {
    const dbManager = DatabaseManager.getInstance();
    const dbInstance = await dbManager.initializeOrGetDatabase(
        DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
    ) as ServiceProgramDatabase;
    return dbInstance.addLoginTokenToUser(email);
};
