import React, { useState, useEffect, useContext } from 'react';
import { DatabaseEnum, DatabaseManager, ServiceProgramDatabase } from '@robotical/analytics-gatherer/dist';
import serviceProgramFirebaseConfig from '../../database/config';
import LoginPage from '../Login';
import LoadingPage from '../../components/LoadingPage';
import Dashboard from '../../components/Dashboard';
import UserContext from '../../store/user-context';
import { isUserRegistered, registerUser } from '../../utils/onboarding';

const LandingPage: React.FC = () => {
    const [loginOrDashboard, setLoginOrDashboard] = useState<"login" | "dashboard">('login');
    const [isLoading, setIsLoading] = useState(true);
    const [serialNumbersToBeAdded, setSerialNumbersToBeAdded] = useState<string[]>([]);
    const userCtx = useContext(UserContext);

    // in the landing page we will first check to see if there is any parameter in the URL
    // if ther is, we check if it is a valid token with email
    // if it is, we use the email to get the token from the user's database entry
    // if the token matches, we log the user in

    // if there is no parameter in the URL, we show the login page
    const hasValidTokenAndEmail = (queryString: string) => {
        if (!queryString) return false;
        const { token, email } = extractParamsFromQueryString(queryString);
        if (!token || !email) return false;
        return true;
    };

    const extractParamsFromQueryString = (queryString: string) => {
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');
        const email = urlParams.get('email');
        const serialNumbers = urlParams.get('sns')?.split(',') || [];
        const school = urlParams.get('school');
        const cta = urlParams.get('cta');
        const expiry = urlParams.get('expiry');
        const isInApp = urlParams.get('inApp') === "true";
        let decodedEmail: string = "";
        if (email) {
            decodedEmail = ServiceProgramDatabase.decodeEmail(email);
        }
        return { token, email: decodedEmail, serialNumbers, school, cta, expiry, isInApp };
    };

    const isTokenValid = async (token: string | null, email: string | null) => {
        if (!token || !email) return false;
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        const fetchedLoginToken = await dbInstance.getLoginToken(email);
        if (fetchedLoginToken && fetchedLoginToken.token === token && new Date(fetchedLoginToken.expirationDate) > new Date()) {
            return true;
        }
        return false;
    };

    const fetchUser = async (email: string) => {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        const user = await dbInstance.getUser(email);
        return user;
    };

    async function processLinkAndUser(email: string, serialNumbers: string[], expiry: string, school: string | null): Promise<boolean> {
        // check if the link has expired (it lasts for 1 day)
        const expiryDate = new Date(expiry);
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        if (expiryDate < currentDate) {
            console.debug("Link has expired");
            setIsLoading(false);
            setLoginOrDashboard('login');
            return false;
        }
        const decodedEmail = ServiceProgramDatabase.decodeEmail(email);
        const decodedSchool = ServiceProgramDatabase.decodeEmail(school || "");

        // check if the user is already registered
        const isEmailInDatabase = await isUserRegistered(decodedEmail);
        if (isEmailInDatabase) {
            console.debug("Email is already in the database");
            // email is already in the database, add the robots to the user's entry
            const user = await fetchUser(email as string);
            if (user) {
                userCtx.setUser(user);
            }
            setLoginOrDashboard('dashboard');
            if (serialNumbers.length > 0) {
                console.debug("email is already in the database -- adding serial numbers")
                setSerialNumbersToBeAdded(serialNumbers);
            }
            setIsLoading(false);
            return true;
        } else {
            console.debug("Email is not in the database, creating user from scratch");
            // create user from scratch
            await registerUser(decodedEmail, decodedSchool, []);
            const user = await fetchUser(email as string);
            if (user) {
                userCtx.setUser(user);
            }
            setSerialNumbersToBeAdded(serialNumbers);
            setLoginOrDashboard('dashboard');
            setIsLoading(false);
            return true;
        }
    }


    useEffect(() => {
        setIsLoading(true);
        const asyncFunc = async () => {
            const queryString = window.location.search;
            const { token, email, serialNumbers, cta, expiry, school, isInApp } = extractParamsFromQueryString(queryString);
            if (isInApp && email) {
                const user = await fetchUser(email as string);
                if (user) {
                    userCtx.setUser(user);
                }
                setLoginOrDashboard('dashboard');
                setIsLoading(false);
                return;
            }
            // check if there is a CTA registration parameter
            if (cta && cta === "registration" && email && expiry) {
                const userWasProcessed = await processLinkAndUser(email, serialNumbers, expiry, school);
                if (userWasProcessed) {
                    return;
                }
            } else if (hasValidTokenAndEmail(queryString)) {
                const tokenIsValid = await isTokenValid(token, email);
                if (tokenIsValid) {
                    const user = await fetchUser(email as string);
                    if (user) {
                        userCtx.setUser(user);
                    }
                    setLoginOrDashboard('dashboard');
                    if (serialNumbers.length > 0) {
                        setSerialNumbersToBeAdded(serialNumbers);
                    }
                    setIsLoading(false);
                    return;
                }
            }
            setIsLoading(false);
            setLoginOrDashboard('login');
        };
        asyncFunc();
    }, []);

    return (
        <>
            {isLoading && <LoadingPage />}
            {!isLoading && loginOrDashboard === 'login' && <LoginPage />}
            {!isLoading && loginOrDashboard === 'dashboard' && <Dashboard serialNumbersToBeAdded={serialNumbersToBeAdded} />}
        </>
    );
}

export default LandingPage;
