import { DatabaseEnum, DatabaseManager, ServiceProgramDatabase } from "@robotical/analytics-gatherer";
import serviceProgramFirebaseConfig from "./config";

export default class EmailManager {

    static async getDb() {
        try {

            const dbManager = DatabaseManager.getInstance();
            const dbInstance = await dbManager.initializeOrGetDatabase(
                DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
            ) as ServiceProgramDatabase;
            return dbInstance;
        } catch (e) {
            console.error("error getting db:", e);
            return null;
        }
    }

    static async addAlternativeEmail(userPrimary: string, alternativeEmail: string): Promise<boolean> {
        const db = await this.getDb();
        if (db) {
            const wasAdded = db.addAlternativeEmailToUser(userPrimary, alternativeEmail);
            return wasAdded;
        } else {
            return false;
        }
    }

    static async removeAlternativeEmail(userPrimary: string, alternativeEmail: string) {
        const db = await this.getDb();
        if (db) {
            const wasRemoved = db.removeAlternativeEmailFromUser(userPrimary, alternativeEmail);
            return wasRemoved;
        } else {
            return false;
        }
    }

    static async swapPrimaryEmail(oldPrimaryEmail: string, oldAlternativeEmail: string) {
        const db = await this.getDb();
        if (db) {
            const wasSwapped = await db.swapPrimaryAndAlternativeEmails(oldPrimaryEmail, oldAlternativeEmail);
            return wasSwapped;
        } else {
            return false;
        }
    }
}