export const AdoptionCountDescription = "Count of unique sessions over time (not cumulative)";
export const RetentionRateDescription = "Percentage of users who visited the platform in the previous [period] and returned in the current [period]";
export const VisitedLessonsDescription = "Visited lessons in the Learning Site over time (not cumulative)";
export const DownloadedLessonsDescription = "Downloaded lessons in the Learning Site over time (not cumulative)";
export const OsCountBiweeklyDescription = "Biweekly count of operating systems used by users";
export const OsCountBimonthlyDescription = "Bimonthly count of operating systems used by users";
export const iOSDeviceModelCountBiweeklyDescription = "Biweekly count of iOS device models used by users";
export const iOSDeviceModelCountBimonthlyDescription = "Bimonthly count of iOS device models used by users";
export const AndroidDeviceModelCountBiweeklyDescription = "Biweekly count of Android device models used by users";
export const AndroidDeviceModelCountBimonthlyDescription = "Bimonthly count of Android device models used by users";
export const WebAppDeviceModelCountBiweeklyDescription = "Biweekly count of Web app device models used by users";
export const WebAppDeviceModelCountBimonthlyDescription = "Bimonthly count of Web app device models used by users";
export const iOSDeviceVersionCountBiweeklyDescription = "Biweekly count of iOS app versions used by users";
export const iOSDeviceVersionCountBimonthlyDescription = "Bimonthly count of iOS app versions used by users";
export const AndroidDeviceVersionCountBiweeklyDescription = "Biweekly count of Android app versions used by users";
export const AndroidDeviceVersionCountBimonthlyDescription = "Bimonthly count of Android app versions used by users";
export const WebAppDeviceVersionCountBiweeklyDescription = "Biweekly count of Web app versions used by users";
export const WebAppDeviceVersionCountBimonthlyDescription = "Bimonthly count of Web app versions used by users";
export const iOSAppVersionCountBiweeklyDescription = "Biweekly count of app versions used by iOS users";
export const iOSAppVersionCountBimonthlyDescription = "Bimonthly count of app versions used by iOS users";
export const AndroidAppVersionCountBiweeklyDescription = "Biweekly count of app versions used by Android users";
export const AndroidAppVersionCountBimonthlyDescription = "Bimonthly count of app versions used by Android users";
export const VisitedScreensBiweeklyDescription = "Biweekly count of screens visited by users";
export const VisitedScreensBimonthlyDescription = "Bimonthly count of screens visited by users";
export const BiweeklyFwVersionCountDescription = "Biweekly count of firmware versions used by users";
export const BimonthlyFwVersionCountDescription = "Bimonthly count of firmware versions used by users";
export const FirmwareVersionCountOverTimeDescription = "Count of firmware versions used by users over time (not cumulative)";
export const MeanRobotSessionDuration = "Mean duration of robot sessions over time (in minutes)";