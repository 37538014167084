import Plot from "react-plotly.js";
import {
  lineGraphLayout,
  rangeSlider,
} from "../../../utils/plots/styles/layouts";
import LoadingSpinner from "../../LoadingSpinner";
import { AdoptionCountGetResponse } from "../../../types/generic";
import GenericProcessor from "../data-processors/GenericProcessor";

type Props = {
  data: AdoptionCountGetResponse | undefined;
};

export default function MeanDuration({ data }: Props) {

  const processedData = GenericProcessor.countOverTimeToTraces(data || {});

  return (
    !!!data ? <LoadingSpinner /> :
      <Plot
        key={JSON.stringify(processedData?.traces)}
        style={{ width: "100%", height: "100%" }}
        data={processedData?.traces || []}
        layout={{
          ...lineGraphLayout,
          xaxis: {
            ...lineGraphLayout.xaxis,
            rangeslider: processedData ? rangeSlider(processedData.smallerDate, processedData.largerDate) : {},
          },
          yaxis: {
            ...lineGraphLayout.yaxis,
            title: "Mins",
          }
        }}
      />
  );
}
