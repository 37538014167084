import { useState } from "react";
import InfoHover from "../InfoHover";
import Modal from "../Modal";
import styles from "./styles.module.css";
import { FaQuestion } from "react-icons/fa";

type PlotFrameProps = {
  title: string;
  children: React.ReactNode;
  plotDescription: string;
};

export default function PlotFrame({ title, children, plotDescription }: PlotFrameProps) {
  const [isPlotMaximised, setIsPlotMaximised] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);

  const maximisePlotHandler = () => {
    setIsPlotMaximised(true);
  };

  const closeModalHandler = () => {
    const timer = setTimeout(() => {
      setIsPlotMaximised(false);
      setIsModalClosing(false);
      document
        .getElementsByTagName("body")[0]
        .setAttribute("style", "overflow: auto");
      clearTimeout(timer);
    }, 7);
    setIsModalClosing(true);
  };

  return (
    <div className={styles.container}>
      <Modal
        show={isPlotMaximised}
        closing={isModalClosing}
        title={title}
        onClose={closeModalHandler}
      >
        {children}
        <div className={styles.InModalPlotDescription}>{plotDescription}</div>
      </Modal>
      <div>
        <div className={styles.title_container}>
          <div>
            <h4 className={styles.title}>{title}</h4>
            <InfoHover
              infoText={plotDescription}
              tooltipId="plot-description-tooltip"
            >
              <FaQuestion height={10} width={10} />
            </InfoHover>
          </div>
          <span className={styles.maximise_plot} onClick={maximisePlotHandler}>
            ▣
          </span>
        </div>
        <hr />
      </div>
      <div className={styles.plot_figure}>
        {children}
      </div>
    </div>
  );
}
