import React, { useContext, useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import styles from './styles.module.css';
import UserProfile from '../../pages/UserProfile';
import UserStats from '../../pages/UserStats';
import UserContext from '../../store/user-context';
import ReactTooltip from "react-tooltip";
import { MAIN_BLUE } from '../../styles/colors';
import { FaRedo } from 'react-icons/fa';
import { DatabaseEnum, DatabaseManager, ServiceProgramDatabase } from '@robotical/analytics-gatherer/dist';
import serviceProgramFirebaseConfig from '../../database/config';
import LoadingPage from '../LoadingPage';
import { addRobotsToUser } from '../../utils/add-robots';
import { toast } from 'react-toastify';

type Props = {
    serialNumbersToBeAdded: string[];
};

const Dashboard: React.FC<Props> = ({ serialNumbersToBeAdded }) => {
    const userCtx = useContext(UserContext);
    const { user, setUser } = userCtx;
    const [refresh, setRefresh] = React.useState(false);
    const [isRefreshing, setIsRefreshing] = React.useState(false);

    const activePage = useLocation().pathname.split("/")[1];

    const fetchUser = async (email: string) => {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        const user = await dbInstance.getUser(email);
        return user;
    };

    useEffect(() => {
        setIsRefreshing(true);
        if (user && user.email) {
            fetchUser(user.email).then((fetchedUser) => {
                if (fetchedUser)
                    setUser(fetchedUser);
            }).catch((error) => console.error(error))
                .finally(() => setIsRefreshing(false));
        }
        else {
            setIsRefreshing(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if there are serial numbers to be added,we add the serial numbers to the user's database entry
        if (serialNumbersToBeAdded.length > 0 && user) {
            setIsRefreshing(true);
            const alreadyRegisteredNumbers = Object.keys(user.serialNumbers || {});
            const uniqueSerialNumbersToBeAdded = serialNumbersToBeAdded.filter((serialNumber) => !alreadyRegisteredNumbers.includes(serialNumber));
            if (uniqueSerialNumbersToBeAdded.length === 0) {
                toast.info("Serial numbers already registered to user.");
                setIsRefreshing(false);
                return;
            } else {
                addRobotsToUser(user.email, uniqueSerialNumbersToBeAdded)
                    .then((wereAdded) => {
                        if (wereAdded) {
                            toast.success("Robots added successfully!");
                            fetchUser(user.email).then((fetchedUser) => {
                                if (fetchedUser) setUser(fetchedUser);
                            })
                                .catch((error) => {
                                    console.error(error);
                                })
                                .finally(() => setIsRefreshing(false));
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Error adding robots to user, please try again.");
                    })
                    .finally(() => setIsRefreshing(false));
            }
        }
    }, []);

    return (
        <div className={styles.container}>
            <ReactTooltip
                id="plot-description-tooltip"
                effect="solid"
                backgroundColor={MAIN_BLUE}
                html={true}
                key={new Date().toISOString()}
            />
            <div className={styles.content}>
                <div className={styles.header}>
                    <h1>Warranty Service Dashboard</h1>
                    <div onClick={() => setRefresh(!refresh)} className={styles.refreshButton}>
                        <FaRedo size={30} />
                        <p>Refresh</p>
                    </div>
                </div>

                <Routes>
                    <Route path="/" element={
                        <div className={styles.dashboard_home}>
                            <p>Welcome to the Warranty Service dashboard!</p>
                            <p>Here you can edit your profile and the robots associated with it.</p>
                        </div>
                    } />
                    <Route path="my-profile" element={
                        isRefreshing ? <div className={styles.dashboard_home}>
                            <LoadingPage />
                        </div> : user && <UserProfile user={user} setUser={setUser} refreshProfile={() => setRefresh(!refresh)} />
                    } />
                    <Route path="my-stats" element={
                        isRefreshing ? <div className={styles.dashboard_home}>
                            <LoadingPage />
                        </div> : user && <UserStats user={user} />
                    } />
                </Routes>
            </div>
            <nav className={styles.navbar}>
                <Link className={[styles.navItem, activePage === "my-profile" ? styles.active : ""].join(" ")} to="/my-profile">My Profile</Link>
                <Link className={[styles.navItem, activePage === "my-stats" ? styles.active : ""].join(" ")} to="/my-stats">My Stats</Link>
            </nav>
        </div>
    );
};

export default Dashboard;
