import React, { useState } from "react";
import styles from './styles.module.css';
import { DatabaseEnum, DatabaseManager, RegisteredUser, ServiceProgramDatabase } from "@robotical/analytics-gatherer/dist";
import { toast } from "react-toastify";
import { RICChannelWebBLE } from "@robotical/ricjs";
import { addRobotsToUser, getSerialNumberFromDevice, isSerialNumberRegistered } from "../../utils/add-robots";
import serviceProgramFirebaseConfig from "../../database/config";

type Props = {
  user: RegisteredUser;
  setUser: (user: RegisteredUser) => void;
};

const UserRobots: React.FC<Props> = ({ user, setUser }) => {
  const [devices, setDevices] = useState<BluetoothDevice[]>([]);
  const [isLoadingMessage, setIsLoadingMessage] = useState<string>("");

  const handleRemove = (serialNumber: string) => {
    // TODO: Remove robot from user's robots
  };

  const fetchUser = async (email: string) => {
    const dbManager = DatabaseManager.getInstance();
    const dbInstance = await dbManager.initializeOrGetDatabase(
      DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
    ) as ServiceProgramDatabase;
    const fetchedUser = await dbInstance.getUser(email);
    if (fetchedUser) {
      setUser(fetchedUser);
    }
  };

  const getOtherSerialNumbers = async () => {
    const otherSerNums = [];
    for (const device of devices) {
      try {
        const serialNumber = await getSerialNumberFromDevice(device);
        if (serialNumber && (!user.serialNumbers || !user.serialNumbers[serialNumber])) {
          const isSerialNoRegistered = await isSerialNumberRegistered(serialNumber);
          if (!isSerialNoRegistered) {
            otherSerNums.push(serialNumber);
          } else {
            toast.warn(`${serialNumber} is already registered, it will not be added.`, { autoClose: 5000 });
          }
        } else {
          toast.warn(`${serialNumber} is already in your robots, it will not be added.`, { autoClose: 5000 });
        }
      } catch (error) {
        toast.error(`Failed to get serial number from ${device.name}.`, { autoClose: 5000 });
        console.error('Error:', error);
      }
    }

    return otherSerNums;
  }

  const saveSerialNumbers = async (otherSerNums: string[]) => {
    const didAdd = await addRobotsToUser(user.email, otherSerNums);
    if (didAdd) {
      await fetchUser(user.email);
      toast.success("Robots added successfully.", { autoClose: 5000 });
      return true;
    } else {
      toast.error("Failed to add robots.", { autoClose: 5000 });
      return false;
    }
  }

  const handleSave = async () => {
    if (devices.length === 0) return;
    setIsLoadingMessage("Scanning for more robots...");

    try {
      setIsLoadingMessage("Getting serial numbers...")
      const otherSerNums = await getOtherSerialNumbers();
      if (!otherSerNums) {
        setIsLoadingMessage("");
        return;
      }

      setIsLoadingMessage("Saving serial numbers...");
      const didSave = await saveSerialNumbers(otherSerNums);
      if (!didSave) {
        setIsLoadingMessage("");
        return;
      }

      setDevices([]);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingMessage("");
    }
  }


  const handleAdd = async () => {
    const namesToIgnore = devices.map((device_) => ({ name: device_.name }));
    try {
      const device = await navigator.bluetooth.requestDevice({
        // acceptAllDevices: true,
        filters: [
          { services: [RICChannelWebBLE.RICServiceUUID] },
        ],
        // @ts-ignore
        exclusionFilters: namesToIgnore.length > 0 ? namesToIgnore : undefined,
        optionalServices: [],
      });
      // add the device to the list of devices only if it's not already there
      if (!devices.find((deviceInList) => deviceInList.id === device.id)) {
        setDevices((prevDevices) => [...prevDevices, device]);
        toast.success(`${device.name} added to the list of robots.`, {
          autoClose: 5000,
        });
      } else {
        toast.error(`${device.name} is already in the list of robots.`, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const sortedSerialNumbers = Object.values(user?.serialNumbers || {}).sort((a, b) => {
    const aDate = new Date(a.date);
    const bDate = new Date(b.date);
    return bDate.getTime() - aDate.getTime();
  });

  return (
    <div className={styles.robotContainer}>
      <h1>My Robots</h1>
      <button className={styles.button_add} onClick={handleAdd}>+ Add New Robot</button>
      {isLoadingMessage && <div>{isLoadingMessage}</div>}
      {devices.length > 0 && (
        <div className={styles.robotContainer}>
          <h2>Robots to add</h2>
          {devices.map((device) => (
            <div className={styles.robot} key={device.id}>
              <div className={styles.robotItem}>
                <label>Robot Name</label>
                <span>{device.name}</span>
              </div>
              <div className={styles.robotItem}>
                <label>Robot ID</label>
                <span>{device.id}</span>
              </div>
            </div>
          ))}
          <div className={styles.robotButtons}>
            <button className={styles.button_cancel} onClick={() => setDevices([])}>Cancel</button>
            <button className={styles.button_add} onClick={handleSave}>Save</button>
          </div>
        </div>
      )}
      {Object.values(sortedSerialNumbers || {}).map((robot) => {
        const registrationDate = new Date(robot.date);
        return (
          <div className={styles.robot} key={robot.serialNumber}>
            {robot.name && <div className={styles.robotItem}>
              <label>Name</label>
              <span>{robot.name}</span>
            </div>}
            <div className={styles.robotItem}>
              <label>Serial Number</label>
              <span>{robot.serialNumber}</span>
            </div>
            <div className={styles.robotItem}>
              <label>Registration Date</label>
              <span>{`${registrationDate.getDate()} ${registrationDate.toLocaleString('default', { month: 'long' })} ${registrationDate.getFullYear()}`}</span>
            </div>
            {/* <button onClick={() => handleRemove(robot.serialNumber)}>Remove</button> */}
          </div>
        )
      })}

    </div>
  );
};

export default UserRobots;
