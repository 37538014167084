import LandingPage from "./pages/LandingPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./store/user-context";

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <ToastContainer />
        <LandingPage />
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
