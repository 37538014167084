import { Transform } from "plotly.js";
import { MAIN_BLUE, GREY, SCREENFREE_BLUE, SAND_YELLOW, SCREENFREE_YELLOW, RED } from "../../../styles/colors";

const COLORS = [
  MAIN_BLUE,
  GREY,
  SCREENFREE_BLUE,
  SAND_YELLOW,
  SCREENFREE_YELLOW,
  RED
];

const randomColor = () => {
  const idx = Math.floor(Math.random() * COLORS.length);
  return COLORS[idx];
};

export const barPlotMarker = () => {
  return { color: randomColor(), opacity: 0.8 };
};

export const barPlotTransforms: Partial<Transform>[] = [
  {
    type: "sort",
    target: "y",
    order: "descending",
  },
];
export const lineGraphOptions = (): any => {
  return {
    type: "scatter",
    line: {
      color: randomColor(),
      width: 1,
    },
  };
};

export const colorsForMultipleGroups = [
  GREY,
  SCREENFREE_BLUE,
  MAIN_BLUE,
  SAND_YELLOW,
];
