import React from 'react';
import { MeanDuration, RegisteredUserSerialNumberGetResponse, RegisteredUser, StatsPropertyKeys } from '@robotical/analytics-gatherer/dist';
import styles from './styles.module.css';
import PlotsArrangement from '../PlotsArrangement/indext';
import PlotFrame from '../PlotFrame';
import { MeanRobotSessionDuration } from '../../utils/plot-descriptions';
import MeanDurationPlot from '../plots/MeanDuration';
import { TicketList } from '../Tickets/List';

type RobotStatsProps = {
    robots: RegisteredUserSerialNumberGetResponse
    user: RegisteredUser;
};

const RobotStats: React.FC<RobotStatsProps> = ({ robots, user }) => {
    const [statsOrTickets, setStatsOrTickets] = React.useState<"stats" | "tickets">("tickets");

    return (
        <div className={styles.statsContainer}>
            <div className={styles.statsHeader}>
                <h2>Robot Stats</h2>
                <div className={styles.statsHeaderButtons}>
                    {/* <button
                        className={[styles.statsHeaderButton, statsOrTickets === "stats" ? styles.active : ""].join(" ")}
                        onClick={() => setStatsOrTickets("stats")}
                    >
                        Stats
                    </button> */}
                    {/* <button
                        className={[styles.statsHeaderButton, statsOrTickets === "tickets" ? styles.active : ""].join(" ")}
                        onClick={() => setStatsOrTickets("tickets")}
                    >
                        Tickets
                    </button> */}
                </div>
            </div>
            {Object.keys(robots).map((serialNo) => {
                const robotName = robots[serialNo].name;
                return (
                    <React.Fragment key={serialNo}>
                        {robotName && <p>{robotName}</p>}
                        <p style={{fontSize: 12}}>{serialNo}</p>
                        {statsOrTickets === "stats"
                            ? <PlotsArrangement key={serialNo}>
                                <PlotFrame title="Mean Robot Duration (m) Over Time" plotDescription={MeanRobotSessionDuration}>
                                    {
                                        robots[serialNo].stats && robots[serialNo].stats!["mean-session-duration" as StatsPropertyKeys]
                                            ? <MeanDurationPlot
                                                data={robots[serialNo].stats!["mean-session-duration" as StatsPropertyKeys] as { [period: string]: MeanDuration }}
                                            />
                                            : <h4 className={styles.no_data}>No data for this plot yet</h4>
                                    }
                                </PlotFrame>
                            </PlotsArrangement>
                            : <TicketList reports={robots[serialNo].reports} serialNumber={serialNo} user={user} />
                        }
                    </React.Fragment>
                );
            })
            }

        </div>
    );
};

export default RobotStats;
