// TicketList.tsx
import React, { useEffect } from 'react';
import TicketItem from '../Item';
import styles from './styles.module.css';
import { DatabaseEnum, DatabaseManager, ServiceProgramDatabase, RegisterReportsGetResponse, RegisteredUser, HSTicket } from '@robotical/analytics-gatherer/dist';
import serviceProgramFirebaseConfig from '../../../database/config';
import { toast } from 'react-toastify';
import { HubspotTicketStatus } from '@robotical/analytics-gatherer/dist/types';
import { getHSTicketsFromReports } from '@robotical/analytics-gatherer/dist/database/ServiceProgramDatabase';

interface TicketListProps {
  reports: RegisterReportsGetResponse | undefined;
  user: RegisteredUser;
  serialNumber: string;
}

export const TicketList: React.FC<TicketListProps> = ({ reports, user, serialNumber }) => {
  const [hubspotTickets, setHubspotTickets] = React.useState<HSTicket[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    const fetchTickets = async () => {
      setIsLoading(true);
      try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
          DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        const fetchedTicketsRaw = await getHSTicketsFromReports(reports || {});
        const fetchedTickets = fetchedTicketsRaw.filter((ticket) => ticket);
        if (fetchedTickets) {
          setHubspotTickets(fetchedTickets);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTickets();
  }, [reports]);

  const onUpdateStatus = async (ticketId: string, status: HubspotTicketStatus) => {
    const dbManager = DatabaseManager.getInstance();
    const dbInstance = await dbManager.initializeOrGetDatabase(
      DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
    ) as ServiceProgramDatabase;
    const didUpdate = await dbInstance.hubspotTicketManager.updateTicketStatus(ticketId, status);
    if (didUpdate) {
      toast.success('Ticket updated successfully', { autoClose: 2000 });
      // update ticket status
      const updatedTickets = hubspotTickets.map((ticket) => {
        if (ticket.hubspotTicketId === ticketId) {
          ticket.status = status;
        }
        return ticket;
      });
      setHubspotTickets(updatedTickets);
    } else {
      toast.error('Failed to update ticket', { autoClose: 2000 });
    }
  };

  if (isLoading) {
    return <h4>Loading...</h4>;
  }

  if (!reports || hubspotTickets.length === 0) {
    return <h4>No tickets yet</h4>;
  }

  return (
    <div className={styles.ticketList}>
      {Object.values(hubspotTickets).map((ticket) => (
        <TicketItem
          key={ticket.hubspotTicketId}
          ticket={ticket}
          onUpdateStatus={(status) => onUpdateStatus(ticket.hubspotTicketId, status)}
        />
      ))}
    </div>
  );
};
