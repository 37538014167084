import React from 'react';
import { RegisteredUser } from '@robotical/analytics-gatherer/dist';
import styles from './styles.module.css';
import RobotStats from '../../components/RobotStats';

type UserStatsProps = {
  user: RegisteredUser;
};

const UserStats: React.FC<UserStatsProps> = ({ user }) => {
  const { serialNumbers } = user;

  return (
    <div className={styles.statsContainer}>
      {/* <div className={styles.averageStats}>
        <h2>Average Stats</h2>
        <div>Average Session Duration: averageStats.meanSessionDuration</div>
        <div>Version Count: averageStats.versionCount</div>
      </div> */}

      <RobotStats robots={serialNumbers} user={user} />
    </div>
  );
};

export default UserStats;
