import { ServiceProgramDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import serviceProgramFirebaseConfig from "../database/config";

export async function registerUser(email: string, establishment: string, serialNumbers: string[]): Promise<boolean> {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        // also register serial numbers
        return dbInstance.registerUser(email, establishment, serialNumbers);
    } catch (error) {
        console.error(`Failed to register user: ${error}`);
        return false;
    }
}

export async function isUserRegistered(email: string): Promise<boolean> {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        return !! await dbInstance.getUser(email);
    } catch (error) {
        console.error(`Failed to check if user is registered: ${error}`);
        return false;
    }
}

export const isSerialNumberRegistered = async (serialNo: string): Promise<boolean> => {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        return dbInstance.isSerialNumberRegistered(serialNo);
    } catch (error) {
        console.error(`Failed to check if serial number is registered: ${error}`);
        return false;
    }
};
