import { useState } from 'react';
import styles from './styles.module.css';
import EmailManager from '../../database/EmailManager';
import LoadingSpinner from '../../components/LoadingSpinner';

type Props = {
    primaryEmail: string;
    alternativeEmails: string[];
    refreshProfile: () => void;
};

export default function ManageEmails({ primaryEmail, alternativeEmails, refreshProfile }: Props) {
    const [newEmail, setNewEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleAddEmail = async () => {
        setIsLoading(true);
        if (newEmail && !alternativeEmails.includes(newEmail) && newEmail !== primaryEmail) {
            const wasEmailAdded = await EmailManager.addAlternativeEmail(primaryEmail, newEmail);
            refreshProfile();
            if (wasEmailAdded) {
                alert('Email added successfully');
            } else {
                alert('Failed to add email');
            }
            setNewEmail('');
        }
        setIsLoading(false);
    };

    const handleRemoveEmail = async (email: string) => {
        setIsLoading(true);
        const wasConfirmationGiven = window.confirm(`Are you sure you want to remove ${email}?`);
        if (!wasConfirmationGiven) {
            setIsLoading(false);
            return;
        }
        const wasEmailRemoved = await EmailManager.removeAlternativeEmail(primaryEmail, email);
        refreshProfile();
        if (wasEmailRemoved) {
            alert('Email removed successfully');
        } else {
            alert('Failed to remove email');
        }
        setIsLoading(false);
    };

    const handleSwapEmail = async (email: string) => {
        setIsLoading(true);
        await EmailManager.swapPrimaryEmail(primaryEmail, email);
        refreshProfile();
        setIsLoading(false);
    };

    return (
        <>
            {
                isLoading ? <div><LoadingSpinner /> </div> :
                    <div className={styles.container}>
                        <div className={styles.emailSection}>
                            <div className={styles.primaryEmailSection}>
                                <h3>Primary Email</h3>
                                <p>{primaryEmail}</p>
                            </div>
                            <div className={styles.separator}></div>
                            <div className={styles.alternativeEmailsSection}>
                                <h3>Alternative Emails</h3>
                                <ul className={styles.emailList}>
                                    {alternativeEmails.map(email => (
                                        <li key={email} className={styles.emailListItem}>
                                            {email}
                                            <div className={styles.alternativeEmailActions}>
                                                <button className={styles.setAsPrimaryButton} onClick={() => handleSwapEmail(email)}>Set as Primary</button>
                                                <button className={styles.removeAlternativeButton} onClick={() => handleRemoveEmail(email)}>Remove</button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={styles.addEmailSection}>
                                <input
                                    type="email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    placeholder="Add new email"
                                    className={styles.addEmailInput}
                                />
                                <button className={styles.addEmailButton} onClick={handleAddEmail}>Add Email</button>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}