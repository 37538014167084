import React from 'react';
import styles from './styles.module.css';
import { Conversation } from '../../../models/Conversation';

type Props = {
    conversation: Conversation;
};

function EmailConversation({ conversation }: Props) {
    return (
        <div className={styles.conversation}>
            <h2>Contact: {conversation.contact}</h2>
            <h3>Status: {conversation.conversationStatus}</h3>
            <div className={styles.messages}>
                {conversation.messages.map((message, index) => (
                    <div
                        key={index}
                        className={`${styles.message} ${message.messageStatus === 'SENT'
                                ? styles.sent
                                : styles.received
                            }`}
                    >
                        <h4>{message.subject}</h4>
                        <p className={styles.text}>{message.text}</p>
                        <span className={styles.date}>
                            {new Date(message.createdAt).toLocaleString()}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmailConversation;