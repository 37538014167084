import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./styles.module.css";

type ModalPropsTypes = {
  show: boolean;
  closing: boolean;
  children: React.ReactNode;
  title: string;
  onClose: () => void;
};

function Modal({ show, closing, children, onClose, title }: ModalPropsTypes) {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
    if (show) {
      window.scrollTo({ top: 0 });
      document.getElementsByTagName("body")[0].setAttribute("style", "overflow: hidden");
    }
  }, [show]);

  const modalContent = show ? (
    <div className={styles.modalOverlay}>
      <div className={closing ? `${styles.modal} closing` : `${styles.modal} opening`} >
        <div className={styles.modalHeader}></div>
        <div className={styles.modalTitleContainer}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.closeSymbol} onClick={onClose}>X</div>
        </div>
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(modalContent, document.getElementById("modal-root")!);
  } else {
    return null;
  }
}

export default Modal;